


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonCol,
  IonApp,
  IonList,
  IonLabel,
  IonRow,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonTitle,
  IonButtons,
  IonBackButton,
    IonRadio,
  IonModal,
  IonGrid,
  IonAvatar,
  alertController,
  IonRadioGroup,
  IonSpinner,
  IonToggle,
  IonNote
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { defineComponent } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import "swiper/swiper-bundle.css"




import {Vue3Lottie}  from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import pickAnimation from '../lotties/shopping-bag-pickup-and-delivery.json'
import deliveryAnimation from '../lotties/delivery-processing.json'

import maska from 'maska'
import  SafeArea  from '../components/SafeArea.vue'

@Options({components: {
      IonContent,
      IonPage,
      IonItem,
      IonHeader,
      IonList,
      IonMenu,
      IonButton,
      IonButtons,
      IonCol,
      IonRow,
      IonTitle,
      IonToolbar,
      IonApp,
      IonIcon,
      IonLabel,
      IonListHeader,
      IonGrid,
      IonAvatar,
      IonMenuToggle,
      IonFooter,
      IonBackButton,
      Swiper, SwiperSlide,
      IonRadio,
      IonModal,
      IonRadioGroup,
      maska,
      SafeArea,
      IonSpinner,
      Vue3Lottie,
      IonToggle,
      IonNote
},
  computed: {
    isDelivery(){
      return  this.$store.state.delivery.isdelivery
    },
    allowDelivery() {
      return this.$store.getters.allowDelivery
    },
    currentLocation() {
      return this.$store.state.user.geolocation
    },
    carts() {
      return this.$store.getters.carts
    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    },
    cartTotal() {
      return this.$store.getters.cartTotal
    },
    cartDelivery() {
      return this.$store.getters.cartDelivery
    },
    getCardType (ob:any) {

      let number =  ob.enderecos.formaspagamento.card.number_v;

      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return "all";

    }

}})

export default class Finalizacao extends Vue {

  store = useStore();

  iconRemove = allIcons.trashOutline
  iconSave = allIcons.saveOutline
  iconVoltar = allIcons.chevronBack

  icon_right = allIcons.chevronForward
  chevronForward = allIcons.chevronForward

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  iconFechar = allIcons.chevronBack

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  pickAnimation = pickAnimation
  deliveryAnimation = deliveryAnimation


  isOpenRef = false
  isOpenConfirmSend = false
  isOpenCard  = false
  isOpenRetorno = false

  isEnviando = false

  isGetToken = false

  loading = false

  basket_id = this.store.state.basket.basket_id
  localizacao = this.store.state.user.geolocation


  finger_print_get = 'https://h.online-metrix.net/fp/clear.png?org_id=k8vif92e&session_id=' + this.basket_id + '&m=2'

  enderecos = {
    cadastrados :{},
    escolhido: {
      first_name:"", last_name:"", address1:"", address2: "",city: "", state_code: "SP"
    },
    notasdeentrega: {
      selecionado: "Portão da casa/prédio",
      opcoes: [{"descricao":"Portão da casa/prédio"}, {"descricao":"Portaria do prédio/condomínio"},{"descricao":"Encontrar com o entregador"}]
    },
    disponibilidades:{
      opcoes:[],
      selecionado:{}
    },
    formaspagamento: {
      formas:[
        { id: 2, nome:"Cartão de Crédito", descricao: "Cartão de Crédito", subdescricao: "Visa, Master Card", img:'assets0004.svg', tipo:"card" },
        { id: 3, nome:"Pagamento na Entrega", descricao: "Pagamento na Entrega", subdescricao: "Pagar no momento da entrega", img:'assets0003.svg', tipo:"fisico" }
      ],
      escolhida:{ id: 2, nome:"Cartão de Crédito", descricao: "Cartão de Crédito", subdescricao: "Visa, Master Card", img:'assets0001.svg', tipo:"card"},
      card: { uuid: null, validade_v:"", validade: "", cvv_v:"", cvv: "", brand:"VISA", number_v : "", number : "", token: "", valid : false, save:false, default:true }
    },
    cartoes:{ escolhido:{}, new: false, salvos: [{uuid:"new", brand: 'assets0004', mask_number: "Adicionar novo Cartão" }] }
  }

  setOpen = (state: boolean) => this.isOpenRef = state;

  navigate (url : string) {
    this.$router.push(url)
  }

  voltar() {
    this.$router.back()
  }

  clearCart() {

    fintailapi
    .post('/carrinho/limpar', { "baskets_id": this.store.state.basket.basket_id })
    .then((response:any) => {

      this.store.state.basket     = response.data.basket;

    })

  }


  getBasket() {

    fintailapi
    .post('/checkout/finalizacao', { "baskets_id": this.store.state.basket.basket_id })
    .then((response:any) => {

      var data = response.data;

      if (data.success) {

        this.enderecos.cartoes.salvos = data.cartoes;

        this.enderecos.cartoes.salvos.push({uuid: "new", brand: 'cards', mask_number: "Adiconar novo Cartão" })

        this.enderecos.escolhido = data.envio;
        this.enderecos.disponibilidades.selecionado =  { descricao : data.janelas };

        if(data.formas){

          this.enderecos.formaspagamento.formas = this.store.state.delivery.isdelivery ? data.formas.entrega : data.formas.retira
          this.enderecos.formaspagamento.escolhida = this.enderecos.formaspagamento.formas[0]

        }
        /*
        this.enderecos.cadastrados = data.addresses;

        this.enderecos.disponibilidades.opcoes = data.availability;
        this.enderecos.disponibilidades.selecionado = data.availability[0].descricao;
        */
        //$scope.setAddressCheckout();


      }

    }).catch((erro:any) => {

      this.presentAlert("Atenção", ""," Verifique sua internet e tente novamente");

      setTimeout(() => {
        this.navigate('/carrinho')
      },500)

    })

  }


	setPayment(forma:any) {


    this.loading = true
    this.setOpen(false);

    this.enderecos.formaspagamento.escolhida  = forma;

		fintailapi
    .post('/checkout/pagamento', { "baskets_id": this.store.state.basket.basket_id, "forma": forma})
		.then((response:any) => {

			var data = response.data;
      this.loading = false
			if (data.success) {

        if(data.type == "card" && this.enderecos.formaspagamento.card.valid){
          this.isOpenCard = true;
          this.enderecos.formaspagamento.card.valid = false;
        }

        if(data.cartoes){
          this.enderecos.cartoes.salvos = data.cartoes;
          this.enderecos.cartoes.salvos.push({uuid: "new", brand: 'cards', mask_number: "Adicionar novo Cartão" })
        }

			}else{
				//menu_add_enderecos.show();
			}

		}).catch((erro:any) => {

      this.presentAlert("Atenção", ""," Verifique sua internet e tente novamente");

      setTimeout(() => {
        this.navigate('/carrinho')
      },500)

    })

  }

	goCheckout() {

		fintailapi
    .post('/user/enderecos', { "user_id": this.store.state.user.id})
		.then((response:any) => {

			var data = response.data;

			if (data.success) {

				this.enderecos.cadastrados = data.addresses;
				this.enderecos.escolhido   = data.addresses[0];

				//this.enderecos.escolhido.first_name = $scope.user.info.name;
			  //this.enderecos.escolhido.last_name  = " ";

				this.enderecos.disponibilidades.opcoes = data.availability;
				this.enderecos.disponibilidades.selecionado = data.availability[0].descricao;

				//$scope.setAddressCheckout();

			}else{

				//menu_add_enderecos.show();

			}

		});

  }

  showConfirmation(){

    if( this.enderecos.formaspagamento.escolhida.tipo == "card" &&  !this.enderecos.formaspagamento.card.valid ){
      this.isOpenCard = true
    }else{
      this.isOpenConfirmSend = true
    }

  }


  setSaveCardToken(opcao:any){
    
    //this.enderecos.cartoes.escolhido=opcao
    this.enderecos.formaspagamento.card.uuid = opcao.uuid

    if(opcao.uuid &&  opcao.uuid != 'new'){

      this.enderecos.cartoes.new = false
      //this.enderecos.cartoes.escolhido = opcao.uuid

      this.enderecos.formaspagamento.card.validade_v = "012000"
      this.enderecos.formaspagamento.card.validade = "01/2000"
      this.getCVV ();

    }else{

      this.enderecos.formaspagamento.card.uuid = null

      this.enderecos.formaspagamento.card.validade_v = ""
      this.enderecos.formaspagamento.card.validade = ""
      this.enderecos.formaspagamento.card.cvv = ""
      this.enderecos.formaspagamento.card.cvv_v = ""
      this.enderecos.formaspagamento.card.default = false
      
      this.enderecos.cartoes.new = true
      //this.enderecos.cartoes.escolhido = 'new'


    }

  }

  getCardToken(){


    if(this.enderecos.formaspagamento.card.default ){
      this.presentAlert("Atenção", ""," Selecione um cartão ou adicione novo cartão a sua conta");
      return
    }


    /*if(this.enderecos.formaspagamento.card.validade_v.length < 6 ){
      this.presentAlert("Atenção", ""," Verifique a VALIDADE do Cartão e tente novamente");
      return
    }*/

    if(this.enderecos.formaspagamento.card.cvv.length < 3 ){
      this.presentAlert("Atenção", ""," Verifique o CVV do Cartão e tente novamente");
      return
    }


    this.isGetToken = true

    fintailapi
    .post('/checkout/pagamento/cartao', {  "baskets_id": this.store.state.basket.basket_id, "payment" : this.enderecos.formaspagamento.card })
		.then((response:any) => {
			var data = response.data;

			if (data.success && data.cardToken ) {

        this.isOpenCard = false
        this.isGetToken = false

        //this.enderecos.formaspagamento.card.number_v = ""
        //this.enderecos.formaspagamento.card.number   = ""

        //TENHO QUE PEGAR O BRAND
        this.enderecos.formaspagamento.card.brand = data.brand;


        this.enderecos.formaspagamento.card.token = data.cardToken
        this.enderecos.formaspagamento.card.valid = true
        this.enderecos.cartoes.escolhido = {}
        this.enderecos.cartoes.new = false


			}else{

        this.isGetToken = false
        this.presentAlert("Atenção", ""," Verifique os dados do Cartão e tente novamente");

      }

      
		}).catch((error) => {

      this.isGetToken = false
      this.presentAlert("Atenção", ""," Verifique os dados do Cartão e tente novamente");

    })

  }


  deleteCardToken(card:any){

    const alert = alertController.create({
      header: 'Remover Cartão',
      subHeader: card.brand+ " " +card.mask_number ,
      message: 'Deseja realmente remover este cartão ?',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {

            }
        },
        {
            text: 'Remover',
            handler: (alertData) => { 
              
              this.isGetToken = true

              fintailapi
              .delete('/checkout/pagamento/cartao/'+card.uuid)
              .then((response:any) => {
                var data = response.data;
                if (data.success) {
                  this.getBasket()
                  this.isGetToken = false
                }
              }).catch((error) => {
                this.isGetToken = false
                this.presentAlert("Atenção", ""," Verifique os dados do Cartão e tente novamente");
              })

            }
        }
    ]
    }).then((response:any) => {

      response.present();

    });

  }

  getCVV () {

    const alert = alertController.create({
      header: 'Código de Segurança',
      subHeader: '',
      message: 'Por favor digite o código de segurança do seu Cartão escolhido',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              this.enderecos.formaspagamento.card.uuid = null
              this.enderecos.cartoes.escolhido = {}
            }
        },
        {
            text: 'Continuar',
            handler: (alertCVV) => { //takes the data
              this.enderecos.formaspagamento.card.cvv    = alertCVV.cvv
              this.enderecos.formaspagamento.card.cvv_v  = alertCVV.cvv
              this.enderecos.formaspagamento.card.default= false
            }
        }
    ],
      inputs: [
        {
          placeholder: 'CVV',
          name: 'cvv',
          type: 'tel'
        }
      ]
    }).then((response:any) => {

      response.present();

    });

    //await alert.present();
  }


  presentAlert = async (titulo:any, subHeader:any, message:any) => {
        const alert = await alertController.create({
          header: titulo,
          subHeader: subHeader,
          message: message,
          buttons: ['OK'],
        });

        await alert.present();
  }

  confirmOrder(){
    this.isEnviando = true


    if( this.enderecos.formaspagamento.card.uuid == 'new' )this.enderecos.formaspagamento.card.uuid=null

    let card = JSON.parse(JSON.stringify( this.enderecos.formaspagamento.card ));

    card.number_v = "";
    card.number   = "";

    fintailapi
    .post('/checkout', { "baskets_id": this.store.state.basket.basket_id, "method" : this.enderecos.formaspagamento.escolhida, "payment" : card, "location": this.localizacao })
		.then((response:any) => {

			var data = response.data;

			if (data.success) {

        this.store.state.order = data.order;

        this.clearCart();

        if(this.enderecos.formaspagamento.escolhida.tipo == "pix" ){

          this.store.state.pix = data.pix;
          this.navigate("/pagamentopix")
          this.isOpenConfirmSend = false

        }else{

          this.navigate("/confirmado")
          this.isOpenConfirmSend = false

        }

			}else{

        this.isEnviando = false
        this.presentAlert("Erro", "", data.error)

      }

		}).catch((error) => {

      this.isEnviando = false
      this.presentAlert("Atenção", ""," Ocorreu um erro ao enviar seu pedido, tente novamente!");

    })

  }

  alterarDados(){

    this.isOpenConfirmSend = false
    //this.voltar()

  }


  showFormas(order:any) {
    this.setOpen(true);
    //this.meuspedidos.selecionado = order;
  }

  created() {

    if(this.store.state.order != undefined && this.store.state.order.final){
      this.navigate("/")
      return
    }


    this.getBasket()

    this.setPayment(this.enderecos.formaspagamento.formas[0])

    //this.goCheckout()

  }

}

